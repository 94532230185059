import React from "react"
import TextBlock from "./TextBlock"
import Panel from "./core/Panel"
import DeviceGallery from "../components/DeviceGallery"
import safeGetImage from "../utils/safeGetImage"

export default function OurWorkPanel({ ourWork, featured }) {
  
  const { title, backgroundImage, featuredCaseStudy } = ourWork

  
  const frontmatter = featured.node.frontmatter
  
  // featured case study info
  const caseStudy = {
    title: frontmatter.title,
    text: frontmatter.shortDescription,
    imageDesktop: frontmatter.imageDesktop,
    imageMobile: frontmatter.imageMobile,
    button: {
      colour: "secondary",
      text: "Explore",
      type: "link",
      link: `/case-studies/${featuredCaseStudy}/`,
      gatsbyLink: true
    },
    panelDescription: "Case Study",
  }
  
  // images for the DeviceExamples component
  const devices = {
    desktop: {
      src: caseStudy.imageDesktop,
      title: "",
    },
    mobile: {
      src: caseStudy.imageMobile,
      title: "",
    },
    tablet: {
      src: caseStudy.imageTablet,
      title: "",
    },
  }

  return (
    <Panel className="our-work" color="white" title={title}>
      <div className="our-work__content">
        <TextBlock {...caseStudy}>{caseStudy.text}</TextBlock>
        <DeviceGallery devices={devices} />
      </div>
      <img
        className="our-work__splat"
        src={safeGetImage(backgroundImage)}
        alt="Splat design"
      />
    </Panel>
  )
}
