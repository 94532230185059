import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Panel from "./core/Panel"

export default function ClientsPanel({ title, subtitle }) {
  const clientsData = useStaticQuery(
    graphql`
      query {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/content/clients/" } }
          sort: { fields: frontmatter___order, order: ASC }
        ) {
          edges {
            node {
              id
              frontmatter {
                path
                name
                slug
                website
                logo {
                  publicURL
                }
              }
              body
            }
          }
        }
      }
    `
  )

  const data = clientsData.allMdx.edges.map(({node}) => ({id: node.id, ...node.frontmatter}))

  return (
    <Panel
      className="clients"
      color="dark"
      title={title}
      subtitle={subtitle}
    >
      <ul className="clients__list">
        {data.map(({ id, website, logo, name, slug }) => (
          <a 
            key={id}
            href={website} 
            target="_blank" 
            rel='noreferrer'
            className="clients__client"
          >
            <li
              data-sal="fade"
              data-sal-duration="2000"
              data-sal-easing="ease"
            >
              <figure>
                <img
                  className={`clients__client__logo logo--${slug}`}
                  src={logo.publicURL}
                  alt={`${name} logo`}
                />
              </figure>
            </li>
          </a>
        ))}
      </ul>
    </Panel>
  )
}
