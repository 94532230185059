import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image";

import topImage from "../assets/images/iphone-top.jpg"
import bottomImage from "../assets/images/iphone-bottom.jpg"

const MobileImageContainer = ({ image }) => (
  <div className="mobile__frame--outer">
    <div className="mobile__frame--inner">
      <div className="mobile__container">
        <img
          className="mobile__container--top"
          alt={ "Mobile header" }
          src={ topImage }
        />
        <figure className={ "mobile__container__image" }>
          <Image
            className="mobile-image"
            alt={ `${ image.title } mobile example` }
            fluid={ image.src.map(image => image.childImageSharp.fluid) }
          />
        </figure>
        <img
          className="mobile__container--bottom"
          alt={ "Mobile buttons" }
          src={ bottomImage }
        />
      </div>
    </div>
  </div>
);

MobileImageContainer.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.arrayOf(PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          aspectRatio: PropTypes.number,
          base64: PropTypes.string,
          sizes: PropTypes.string,
          src: PropTypes.string,
          srcSet: PropTypes.string,
        })
      })
    })),
    title: PropTypes.string,
  }).isRequired,
}

export default MobileImageContainer
