import React from "react"
import PropTypes from "prop-types"
import Image from 'gatsby-image'

const Staff = ({ staff }) => {
  return (
    <li
      className="our-team__member"
      data-sal="fade"
      data-sal-duration="2000"
      data-sal-easing="ease"
    >
      <Image
        className="our-team__member__image"
        width={ 112 }
        fixed={ staff.image.map(image => image.childImageSharp.fluid) }
        alt={ staff.name }
      />
      <p className="our-team__member__name">{ staff.name }</p>
      <p className="our-team__member__title">{ staff.jobTitle }</p>
    </li>
  )
}

Staff.defaultProps = {
  staff: {},
}

Staff.propTypes = {
  staff: PropTypes.shape({
    name: PropTypes.string.isRequired,
    jobTitle: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
}

export default Staff
