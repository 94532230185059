import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/SEO"
import Hero from "../components/HomepageHero"
import TechnologiesPanel from "../components/HomepagePanelTechnologies"
import ClientsPanel from "../components/HomepagePanelClients"
import TeamPanel from "../components/HomepagePanelOurTeam"
import ServicesPanel from "../components/HomepagePanelServices"
import OurWorkPanel from "../components/HomepagePanelOurWork"
import ContactForm from "../components/ContactForm"

const Homepage = () => {

  const data = useStaticQuery(graphql`
    {
      page: mdx(fileAbsolutePath: { regex: "/content/index/" }) {
        body
        frontmatter {
          path
          title
          hero {
            title
            subtitle
            imageDesktop
            imageMobile
            buttons {
              colour
              link
              text
              type
            }
          }
          services {
            title
            subtitle
          }
          technologies {
            title
            subtitle
          }
          ourTeam {
            title
          }
          ourWork {
            title
            backgroundImage
            featuredCaseStudy
          }
          clients {
            title
            subtitle
          }
        }
      }
      caseStudies: allMdx(
        filter: { fileAbsolutePath: { regex: "/content/caseStudies/" } }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              path
              shortDescription
              imageDesktop {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              imageMobile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  let {
    hero,
    services,
    technologies,
    clients,
    ourTeam,
    ourWork,
  } = data.page.frontmatter


  // get featured case study
  const caseStudies = data.caseStudies.edges;
  const featuredCaseStudy = ourWork.featuredCaseStudy;
  const featured = caseStudies.filter(
    caseStudy =>
      caseStudy.node.frontmatter.path === `/case-studies/${ featuredCaseStudy }`
  )[0]

  return (
    <>
      <SEO title="Home" />
      <Hero hero={ hero } />
      <ServicesPanel services={ services } />
      <OurWorkPanel ourWork={ ourWork } featured={ featured } />
      <ClientsPanel title={ clients.title } subtitle={ clients.subtitle } />
      <TeamPanel ourTeam={ ourTeam } />
      <TechnologiesPanel technologies={ technologies } />
      <ContactForm />
    </>
  )
}

export default Homepage