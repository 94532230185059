import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image";

const DesktopImageContainer = ({ image }) => {
  return (
    <figure className={ "desktop-image-container" }>
      <Image
        className="desktop-image"
        alt={ `${ image.title } mobile example` }
        fluid={ image.src.map(image => image.childImageSharp.fluid) }
      />
    </figure>
  )
}

DesktopImageContainer.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.arrayOf(PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          aspectRatio: PropTypes.number,
          base64: PropTypes.string,
          sizes: PropTypes.string,
          src: PropTypes.string,
          srcSet: PropTypes.string,
        })
      })
    })),
    title: PropTypes.string,
  }).isRequired,
}

export default DesktopImageContainer
