import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Panel from "./core/Panel"
import Button from "./core/Button"
import Staff from "./Staff"
import PropTypes from "prop-types"

const OurTeamPanel = ({ ourTeam }) => {
  const staffCards = useStaticQuery(
    graphql`
      query {
        allMdx(
          filter: {
            fileAbsolutePath: { regex: "/content/staff/" }
            frontmatter: { isActive: { eq: true } }
          }
          sort: { fields: frontmatter___order, order: ASC }
        ) {
          edges {
            node {
              id
              frontmatter {
                name
                image {
                  childImageSharp {
                    fluid(maxWidth: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                jobTitle
              }
              body
            }
          }
        }
      }
    `
  )

  const { title } = ourTeam
  const staff = staffCards.allMdx.edges

  return (
    <Panel className="our-team" color="dark" title={title}>
      <ul className="our-team__list">
        {staff.map(({ node }, i) => (
          <Staff key={node.id} staff={node.frontmatter} />
        ))}
      </ul>
      <footer className="our-team__footer">
        <Button
          button={{
            colour: "tertiary",
            text: "Meet the team",
            link: "/who-we-are",
            type: "link",
            gatsbyLink: true,
          }}
        />
      </footer>
    </Panel>
  )
}

OurTeamPanel.propTypes = {
  technologies: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }),
}

export default OurTeamPanel
