import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

import ResponsiveSVG from "./ResponsiveSVG"

export default function Hero({ hero }) {
  let { title, subtitle, buttons, imageMobile, imageDesktop } = hero

  return (
    <section className="container homepage-panel homepage-hero">
      <ResponsiveSVG
        imageDesktop={imageDesktop}
        imageMobile={imageMobile}
        breakpoint={850}
        className="homepage-hero__background-image"
        alt="Travel to the Moon"
      />
      <div className="homepage-hero__text-content">
        <h1 
          className="homepage-hero__heading"
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-easing="ease"
        >{title}</h1>
        {subtitle ? (
          <h2 
            className="homepage-hero__subheading"
            data-sal="fade"
            data-sal-duration="2000"
            data-sal-delay={500}
            data-sal-easing="ease"
          >{subtitle}</h2>
        ) : null}
        {buttons ? (
          <div 
            className="homepage-hero__buttons"
            data-sal="fade"
            data-sal-duration="2000"
            data-sal-delay={1000}
            data-sal-easing="ease"
          >
            <Link
              data-text={buttons[0].text}
              className={`button button-${buttons[0].colour}`} 
              to="/contact"
            >{ buttons[0].text}</Link>
            <Link data-text={buttons[1].text} className={`button button-${buttons[1].colour}`} to={buttons[1].link}>{buttons[1].text}</Link>
          </div>
        ) : null}
      </div>
    </section>
  )
}

Hero.propTypes = {
  hero: PropTypes.object.isRequired,
}
