import PropTypes from "prop-types"
import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import safeGetImage from "../utils/safeGetImage"

export default function ServiceCard({ service, i }) {
  return (
    <li 
      className="service-card"
      data-sal="fade"
      data-sal-duration="1000"
      data-sal-delay={250*i}
      data-sal-easing="ease-out"
    >
      <img
        className="service-card__icon"
        src={safeGetImage(service.frontmatter.serviceIcon)}
        alt={`${service.frontmatter.title} icon`}
      />
      <h2 className="service-card__title">{service.frontmatter.title}</h2>
      <div className="service-card__content">
        <MDXRenderer>{service.body}</MDXRenderer>
      </div>
    </li>
  )
}

ServiceCard.propTypes = {
  service: PropTypes.object.isRequired,
}
