import React from "react"

import safeGetImage from "../utils/safeGetImage"

const ResponsiveSVG = ({
  imageDesktop,
  imageMobile,
  breakpoint,
  className,
  alt,
}) => {
  const desktopHeroURL = safeGetImage(imageDesktop),
    mobileHeroURL = safeGetImage(imageMobile)

  return (
    <picture className={className}>
      <source
        media={`(max-width: ${breakpoint - 1}px)`}
        srcSet={mobileHeroURL}
      />
      <source media={`(min-width: ${breakpoint}px)`} srcSet={desktopHeroURL} />
      <img src={desktopHeroURL} alt={alt} />
    </picture>
  )
}

export default ResponsiveSVG
