import React from "react"
import PropTypes from "prop-types"

import DesktopImageContainer from "./DesktopImageContainer"
import MobileImageContainer from "./MobileImageContainer"
import TabletImageContainer from "./TabletImageContainer"

const DeviceGallery = ({ devices }) => {
  const { desktop, mobile, tablet } = devices
  return (
    <section
      className="website-examples"
      data-sal="fade"
      data-sal-duration="1000"
      data-sal-easing="ease-out"
    >
      { desktop && desktop.src && <DesktopImageContainer image={ desktop } /> }
      { mobile && mobile.src && <MobileImageContainer image={ mobile } /> }
      { tablet && tablet.src && <TabletImageContainer image={ tablet } /> }
    </section>
  )
}

DeviceGallery.propTypes = {
  devices: PropTypes.shape({
    desktop: PropTypes.shape({
      src: PropTypes.arrayOf(PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.shape({
            aspectRatio: PropTypes.number,
            base64: PropTypes.string,
            sizes: PropTypes.string,
            src: PropTypes.string,
            srcSet: PropTypes.string,
          })
        })
      })),
      title: PropTypes.string,
    }),
    mobile: PropTypes.shape({
      src: PropTypes.arrayOf(PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.shape({
            aspectRatio: PropTypes.number,
            base64: PropTypes.string,
            sizes: PropTypes.string,
            src: PropTypes.string,
            srcSet: PropTypes.string,
          })
        })
      })),
      title: PropTypes.string,
    }),
    tablet: PropTypes.shape({
      src: PropTypes.arrayOf(PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.shape({
            aspectRatio: PropTypes.number,
            base64: PropTypes.string,
            sizes: PropTypes.string,
            src: PropTypes.string,
            srcSet: PropTypes.string,
          })
        })
      })),
      title: PropTypes.string,
    }),
  }),
}

export default DeviceGallery
