import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image";

const TabletImageContainer = ({ image }) => {
  return (
    <div className="tablet__container">
      <div className="tablet__container--inner">
        <figure className={ "tablet__image-container" }>
          <Image
            className="tablet__image"
            alt={ `${ image.title } mobile example` }
            fluid={ image.src.map(image => image.childImageSharp.fluid) }
          />
        </figure>
      </div>
    </div>
  )
}

TabletImageContainer.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.arrayOf(PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          aspectRatio: PropTypes.number,
          base64: PropTypes.string,
          sizes: PropTypes.string,
          src: PropTypes.string,
          srcSet: PropTypes.string,
        })
      })
    })),
    title: PropTypes.string,
  }).isRequired,
}

export default TabletImageContainer
