import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import ServiceCard from "./ServiceCard"
import Panel from "./core/Panel"

export default function ServicesPanel({ services }) {
  const servicesData = useStaticQuery(
    graphql`
      query {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/content/services/" } }
          sort: { fields: frontmatter___order, order: ASC }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                serviceIcon
              }
              body
            }
          }
        }
      }
    `
  )

  let { title, subtitle } = services
  let data = servicesData.allMdx.edges

  return (
    <Panel
      className="services"
      color="purple"
      title={title}
      subtitle={subtitle}
    >
      <ul className="services__list">
        {data.map(({ node }, i) => (
          <ServiceCard key={node.id} service={node} i={i}/>
        ))}
      </ul>
    </Panel>
  )
}
