import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import Icon from "./core/Icon"
import Panel from "./core/Panel"

const TechnologiesPanel = ({ technologies }) => {
  const technologyIcons = useStaticQuery(
    graphql`
      query {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/content/technologies/" } }
          sort: { fields: frontmatter___order, order: ASC }
        ) {
          edges {
            node {
              id
              frontmatter {
                text
                path
                icon {
                  publicURL
                }
              }
              body
            }
          }
        }
      }
    `
  )

  const { title, subtitle } = technologies
  const icons = technologyIcons.allMdx.edges

  return (
    <Panel
      className="technologies"
      color="white"
      title={title}
      subtitle={subtitle}
    >
      <ul className="technologies__list">
        {icons.map(({ node }) => (
          <Icon
            link={node.frontmatter.path}
            className="technologies__icon"
            key={node.id}
            {...node}
          />
        ))}
      </ul>
    </Panel>
  )
}

TechnologiesPanel.propTypes = {
  technologies: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }),
}

export default TechnologiesPanel
